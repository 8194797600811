<template>
  <div class="f-z" v-if="Pagefy.length != 0">
    <!-- <paging-fy2
        :Pagefy="userlist"
        @nextpage="nextpage"
        :pageSize="8"
      ></paging-fy2> -->
    <div @click="paging(1)" class="paging1">上一页</div>
    <div class="paging2">{{ Page2 }}</div>
    <div @click="paging(2)" class="paging1">下一页</div>
  </div>
</template>

<script>
export default {
  props: ["Pagefy", "pageSize"],
  data() {
    return {
      Page2: 1,
    };
  },
  methods: {
    paging(v) {
      if (v == 1 && this.Page2 != 1) {
        this.Page2--;
        this.$emit("nextpage", this.Page2);
      } else if (v == 2 && this.Pagefy.length == this.pageSize) {
        this.Page2++;
        this.$emit("nextpage", this.Page2);
      } else {
        this.$message({
          duration: 1000,
          message: "没有更多了",
          type: "success"
        });
      }
    }
  }
};
</script>

<style scoped lang="less">
.paging1 {
  padding: 5px 10px;
  border: 1px solid #b8b8b8;
  font-size: 12px;
  color: #b8b8b8;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  border-radius: 4px;
}

.paging2 {
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid #fc781d;
  color: #fc781d;
  margin: 0 10px;
}
</style>
