<template>
    <div>
        <el-table :data="DataList" :fit="true" :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }" :v-loading="loading">
            <el-table-column v-for="(opt, index) in option" :key="index" :label="opt.name"
                :prop="opt.value"></el-table-column>
            <slot></slot>
        </el-table>

        <paging-fy v-if="showPage && total" @currentPageChange="changeCurrentPage" :currentPage="currentPage"
            :total="total"></paging-fy>
    </div>
</template>

<script>
export default {
    props: {
        DataList: {
            type: Array,
        },
        option: {
            type: Array
        },
        loading: {
            type: Boolean
        },
        showPage: {
            type: Boolean,
            default: true
        },
        total: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            currentPage: 1
        };
    },

    mounted() {

    },

    methods: {
        changeCurrentPage(v) {
            this.currentPage = v
            this.$emit('changePage', v);
        }
    },
};
</script>

<style lang="scss" scoped></style>