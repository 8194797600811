<template>
  <el-upload
    class="avatar-uploader"
    :action="domain"
    :show-file-list="false"
    :on-success="UploadFaceImg"
    :data="QiNiYunL"
    :before-upload="gettoken"
    multiple
  >
    <el-image v-if="img || url" :src="img ? img : url" alt="" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"> </i>
    <p v-if="img == '' && url == ''" class="upload-pp">上传图片</p>
  </el-upload>
</template>

<script>
export default {
  props: ["clear", "url"],
  data() {
    return {
      img: "",
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: ""
      }
    };
  },
  mounted() {
    this.gettoken();
  },
  methods: {
    close() {
      this.img = "";
    },
    UploadFaceImg(file) {
      this.img = this.upload_qiniu_addr + file.key;
      this.$emit("getImgUrl", this.img);
    },
    gettoken() {
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp + ".png",
        type: "pic"
      };
      this.axios({
        method: "post",
        url: "https://www.curiousmore.com/qmore/push/upload/getToken",
        data: data
      })
        .then(res => {
          this.QiNiYunL.token = res.data.data;
          this.QiNiYunL.key = data.key;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.gettoken();
  },
  watch: {
    url(newV, oldV) {
      this.img = "";
    }
  }
};
</script>

<style>
.upload-pp {
  position: absolute;
  top: 45px;
  width: 100px;
  color: #666666;
  font-size: 12px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-upload-list__item,
.el-upload-list--picture-card,
.el-upload-list__item-actions,
.el-upload--picture-card,
.avatar-uploader-icon {
  font-size: 14px !important;
  color: #fc781d;
  width: 100px !important;
  height: 100px !important;
  line-height: 80px !important;
  text-align: center;
}
.avatar {
  width: 100px !important;
  height: 100px !important;
  display: block;
  /* margin-right:0px; */
}
</style>
