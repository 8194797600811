import Vue from "vue";
import Router from "vue-router";
import { Message } from "element-ui";
Vue.use(Router);
const router = new Router({
  // base: "/user/",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "homeG",
    },

    {
      path: "/homeG",
      name: "homeG",
      component: () => import("../home.vue"),
    },

    {
      path: "/login",
      name: "login",
      component: () => import("../views/login.vue"),
    },

    {
      path: "/DownloadApp",
      name: "DownloadApp",
      component: () => import("../views/applet/DownloadApp.vue"),
    },
    {
      path: "/Agencyapplet",
      name: "Agencyapplet",
      component: () => import("../views/applet/Agencyapplet.vue"),
    },
    {
      path: "/Merchantapplet",
      name: "Merchantapplet",
      component: () => import("../views/applet/Merchantapplet.vue"),
    },
    {
      path: "/Downloadapplet",
      name: "Downloadapplet",
      component: () => import("../views/applet/Downloadapplet.vue"),
    },
    {
      path: "/Partners",
      name: "Partners",
      component: () => import("../views/Partners/Partners.vue"),
    },
    {
      path: "/Business",
      name: "Business",
      component: () => import("../views/Partners/Business.vue"),
    },
    {
      path: "/MerchantLogin",
      name: "MerchantLogin",
      component: () => import("../views/Partners/login.vue"),
    },
    {
      path: "/joinTz",
      name: "joinTz",
      component: () => import("../views/Headofregiment/joinTz.vue"),
    },
    {
      path: "/main",
      name: "main",
      component: () => import("../main.vue"),
      redirect: {
        name: "Home",
      },
      children: [
        {
          path: "AppRaise",
          name: "AppRaise",
          component: () => import("../views/AppRaise/AppRaise.vue"),
        },
        {
          path: "timHome",
          name: "timHome",
          component: () => import("../views/timMessage/timHome.vue"),
        },
        {
          path: "leisuretime",
          name: "leisuretime",
          component: () => import("../views/leisuretime/leisuretime.vue"),
        },
        {
          path: "coachDetailed",
          name: "coachDetailed",
          component: () => import("../views/coach/coachDetailed.vue"),
        },
        {
          path: "GaudMap",
          name: "GaudMap",
          component: () => import("../views/coach/GaudMap.vue"),
        },
        {
          path: "SelectCoach",
          name: "SelectCoach",
          component: () => import("../views/coach/SelectCoach.vue"),
        },
        {
          path: "WyCoach",
          name: "WyCoach",
          component: () => import("../views/coach/WyCoach.vue"),
        },
        {
          path: "publishneed",
          name: "publishneed",
          component: () => import("../views/coach/publishneed.vue"),
        },
        {
          path: "wyneed",
          name: "wyneed",
          component: () => import("../views/coach/wyneed.vue"),
        },
        {
          path: "privilege",
          name: "privilege",
          component: () => import("../views/PersonalCenter/privilege.vue"),
        },

        {
          path: "myHome",
          name: "myHome",
          component: () => import("../views/PersonalCenter/myHome.vue"),
          redirect: {
            name: "MyPage",
          },
          children: [
            {
              path: "MyPage",
              name: "MyPage",
              component: () => import("../views/PersonalCenter/MyPage.vue"),
            },
            {
              path: "Writeoff",
              name: "Writeoff",
              component: () => import("../views/PersonalCenter/Writeoff.vue"),
            },
            {
              path: "OrderCenter",
              name: "OrderCenter",
              component: () =>
                import("../views/PersonalCenter/OrderCenter.vue"),
            },
            {
              path: "RedemptionList",
              name: "RedemptionList",
              component: () =>
                import("../views/PersonalCenter/RedemptionList.vue"),
            },
            {
              path: "Cardandvoucher",
              name: "Cardandvoucher",
              component: () =>
                import("../views/PersonalCenter/Cardandvoucher.vue"),
            },
            {
              path: "MyBenefits",
              name: "MyBenefits",
              component: () => import("../views/PersonalCenter/MyBenefits.vue"),
            },
            {
              path: "Collection",
              name: "Collection",
              component: () => import("../views/PersonalCenter/Collection.vue"),
            },
            {
              path: "MemberCenter",
              name: "MemberCenter",
              component: () =>
                import("../views/PersonalCenter/MemberCenter.vue"),
            },
            {
              path: "consume",
              name: "consume",
              component: () => import("../views/PersonalCenter/consume.vue"),
            },
            {
              path: "relatives",
              name: "relatives",
              component: () => import("../views/PersonalCenter/relatives.vue"),
            },
            {
              path: "personal",
              name: "personal",
              component: () => import("../views/PersonalCenter/personal.vue"),
            },
            {
              path: "customerservice",
              name: "customerservice",
              component: () =>
                import("../views/PersonalCenter/customerservice.vue"),
            },
          ],
        },
        {
          path: "HelpHome",
          name: "HelpHome",
          component: () => import("../views/help/HelpHome.vue"),
          redirect: {
            name: "Frequentproblems",
          },
          children: [
            {
              path: "Frequentproblems",
              name: "Frequentproblems",
              component: () => import("../views/help/Frequentproblems.vue"),
            },
            {
              path: "currentversion",
              name: "currentversion",
              component: () => import("../views/help/currentversion.vue"),
            },
            {
              path: "Feedback",
              name: "Feedback",
              component: () => import("../views/help/Feedback.vue"),
            },
            {
              path: "Cardpurchase",
              name: "Cardpurchase",
              component: () => import("../views/help/Cardpurchase.vue"),
            },
            {
              path: "MembershipAgreement",
              name: "MembershipAgreement",
              component: () => import("../views/help/MembershipAgreement.vue"),
            },
            {
              path: "NetworkConvention",
              name: "NetworkConvention",
              component: () => import("../views/help/NetworkConvention.vue"),
            },
            {
              path: "PrivacyAgreement",
              name: "PrivacyAgreement",
              component: () => import("../views/help/PrivacyAgreement.vue"),
            },
            {
              path: "Rechargeprotocol",
              name: "Rechargeprotocol",
              component: () => import("../views/help/Rechargeprotocol.vue"),
            },
            {
              path: "UserAgreement",
              name: "UserAgreement",
              component: () => import("../views/help/UserAgreement.vue"),
            },
          ],
        },
        {
          path: "TZhome",
          name: "TZhome",
          component: () => import("../views/Headofregiment/TZhome.vue"),
          redirect: {
            name: "extension",
          },
          children: [
            {
              path: "extension",
              name: "extension",
              component: () => import("../views/Headofregiment/extension.vue"),
            },
            {
              path: "Taskranking",
              name: "Taskranking",
              component: () =>
                import("../views/Headofregiment/Taskranking.vue"),
            },
            {
              path: "Voucherrecord",
              name: "Voucherrecord",
              component: () =>
                import("../views/Headofregiment/Voucherrecord.vue"),
            },
            {
              path: "invitation",
              name: "invitation",
              component: () => import("../views/Headofregiment/invitation.vue"),
            },
            {
              path: "RevenueCenter",
              name: "RevenueCenter",
              component: () =>
                import("../views/Headofregiment/RevenueCenter.vue"),
            },
            {
              path: "TZhelp",
              name: "TZhelp",
              component: () => import("../views/Headofregiment/TZhelp.vue"),
            },
          ],
        },
        {
          path: "membershipcard",
          name: "membershipcard",
          component: () => import("../views/TopNav/membershipcard.vue"),
        },
        {
          path: "Home",
          name: "Home",
          component: () => import("../views/Home.vue"),
        },
        {
          path: "Basketball",
          name: "Basketball",
          component: () => import("../views/TopNav/Basketball.vue"),
        },

        {
          path: "Buycoupons",
          name: "Buycoupons",
          component: () => import("../views/TopNav/Buycoupons.vue"),
        },
        {
          path: "Popularinstitutions",
          name: "Popularinstitutions",
          component: () => import("../views/TopNav/Popularinstitutions.vue"),
        },
        {
          path: "curriculum",
          name: "curriculum",
          component: () => import("../views/details/curriculum.vue"),
        },
        {
          path: "comment",
          name: "comment",
          component: () => import("../views/details/comment.vue"),
        },
        {
          path: "Coursepayment",
          name: "Coursepayment",
          component: () => import("../views/details/Coursepayment.vue"),
        },
        {
          path: "mechanism",
          name: "mechanism",
          component: () => import("../views/details/mechanism.vue"),
        },
        {
          path: "Allinstitutions",
          name: "Allinstitutions",
          component: () => import("../views/details/Allinstitutions.vue"),
        },
        {
          path: "SearchPage",
          name: "SearchPage",
          component: () => import("../views/TopNav/SearchPage.vue"),
        },
        // {
        //   path: "MessagePage",
        //   name: "MessagePage",
        //   component: () => import("../views/PersonalCenter/MessagePage.vue"),
        // },
        {
          path: "Evaluationpage",
          name: "Evaluationpage",
          component: () => import("../views/PersonalCenter/Evaluationpage.vue"),
        },
        {
          path: "Appointmentpage",
          name: "Appointmentpage",
          component: () =>
            import("../views/PersonalCenter/Appointmentpage.vue"),
        },
        {
          path: "AboutPage",
          name: "AboutPage",
          component: () => import("../views/PersonalCenter/AboutPage.vue"),
        },
        {
          path: "Venuedetailed",
          name: "Venuedetailed",
          component: () => import("../views/Venue/Venuedetailed.vue"),
        },
        {
          path: "Venuereservation",
          name: "Venuereservation",
          component: () => import("../views/Venue/Venuereservation.vue"),
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("UserToken");
  let applet =
    to.path == "/Merchantapplet" ||
    to.path == "/Downloadapplet" ||
    to.path == "/Agencyapplet" ||
    to.path == "/DownloadApp" ||
    to.path == "/Business" ||
    to.path == "/MerchantLogin" ||
    to.path == "/Partners";
  if (to.path == "/homeG" || to.path == "/login" || applet) {
    next();
  } else if (!token && to.path != "/main/Home") {
    if (to.path != "/main/Home") {
      Message({
        duration: 1000,
        message: "请登录",
        type: "error",
      });
      router.push({
        name: "Home",
      });
    } else {
      router.push({
        name: "Home",
      });
    }
  } else {
    next();
  }
});
export default router;
