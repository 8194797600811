<template>
  <div>
    <ul class="top-ul flex">
      <li :class="['top-li', topindex == index ? 'li' : '']" v-for="(item, index) in toplist" :key="index"
        @click="topclick(index)">
        {{ item }}
      </li>
    </ul>
    <ul class="top-ul2 flex" v-if="toplist2">
      <li :class="['top-li2', topindex2 == index ? 'li2' : '']" v-for="(item, index) in toplist2" :key="index"
        @click="topclick2(index)">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["toplist", "toplist2"],
  data() {
    return {
      topindex: 0,
      topindex2: 0
    };
  },
  methods: {
    topclick(e) {
      this.topindex = e;
      this.topindex2 = 0;
      this.$emit('Eventclick', e)
    },
    topclick2(e) {
      this.topindex2 = e;
      this.$emit('Eventclick1', e)
    }
  }
};
</script>

<style scoped lang="less">
.top-ul {
  padding-bottom: 20px ;

  .top-li {
    color: #333333;
    cursor: pointer;
    font-size: 18px;

    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  .li {
    color: #fc781d;
  }
}

.top-ul2 {
  padding-bottom: 20px ;

  .top-li2 {
    cursor: pointer;
    color: #333333;
    padding: 4px 12px;
    font-size: 14px;
    background-color: #f7f8fa;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .li2 {
    background-color: #fff4ed;
    color: #fc781d;
  }
}
</style>
