<template>
  <div class="fyb f-z">
    <!-- <paging-fy  @currentPageChange="currentPageChange" 
      :currentPage="currentPage" :total="courseTotal"></paging-fy> -->
    <div class="fyb1" v-if="total">
      <el-pagination
        :current-page="currentPage"
        :total="total"
        @current-change="currentPageChange"
        :page-size="page?page:10"
      >
      </el-pagination>
    </div>
    <div class="fyb2" v-if="total">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        @current-change="currentPageChange"
        :page-size="page?page:10"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagingfy",
  props: ["currentPage", "total",'page'],
  methods: {
    currentPageChange(e) {
      this.$emit("currentPageChange", e);
    }
  }
};
</script>

<style lang="less">
.fyb {
  height: 50px;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  @media screen and(max-width: 768px) {
    justify-content: center;
  }
  .fyb1 {
    display: block;
    @media screen and(max-width: 768px) {
      display: none;
    }
  }
  .fyb2 {
    display: none;
    @media screen and(max-width: 768px) {
      display: block;
    }
  }
}
</style>
