import tabs from "../components/tabs.vue";
import pagingfy from "../components/pagingfy.vue";
import pagingfy2 from "../components/pagingfy2.vue";
import Coupon from "../components/Coupon.vue";
import Coupon2 from "../components/Coupon2.vue";
import Homegroup from "../components/Home/Homegroup.vue";
import Homegroup2 from "../components/Home/Homegroup2.vue";
import HomeMechanism from "../components/Home/HomeMechanism.vue";
import avatarUploader from "../components/avatarUploader.vue";
import uploadDX from "../components/uploadDX.vue";
import CourseDetails from "../components/Home/CourseDetails.vue";
import CourseDetails2 from "../components/Home/CourseDetails2.vue";
import autoTable from "../components/autoTable/index.vue";
import userSelect from "../components/userSelect.vue";
import CoachList from "../components/CoachList.vue";
import BlanSpace from "../components/BlanSpace.vue";
import VenueList from "../components/VenueList.vue";
const components = {
  install(Vue) {
    Vue.component("Z-tabs", tabs);
    Vue.component("paging-fy", pagingfy);
    Vue.component("paging-fy2", pagingfy2);
    Vue.component("C-oupon", Coupon);
    Vue.component("C-oupon2", Coupon2);
    Vue.component("Home-group", Homegroup);
    Vue.component("Home-group2", Homegroup2);
    Vue.component("Home-Mechanism", HomeMechanism);
    Vue.component("avatar-uploader", avatarUploader);
    Vue.component("upload-DX", uploadDX);
    Vue.component("Course-Details", CourseDetails);
    Vue.component("Course-Details2", CourseDetails2);
    Vue.component("auto-table", autoTable);
    Vue.component("coach-list", CoachList);
    Vue.component("user-select", userSelect);
    Vue.component("blan-space", BlanSpace);
    Vue.component("venue-list", VenueList);
  },
};
export default components;
