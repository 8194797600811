import axios from "axios";
import router from "../router";
import { Loading } from "element-ui";
import { Message } from "element-ui";
let loading;
let requestCount = 0;
function startLoading() {
  if (requestCount == 0) {
    loading = Loading.service({
      lock: true,
      text: "加载中……",
      background: "rgba(0, 0, 0, 0.7)",
    });
  }
  requestCount++;
}

function endLoading() {
  loading.close();
}
var http = "https://www.curiousmore.com/qmore/";
// let http = "http://192.168.8.102:8768/eg-api/"; /*后端本地接口 */
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.interceptors.request.use(
  function (config) {
    config.headers.token = localStorage.getItem("UserToken");
    // if (router.app.$route.path == "/main/Home") {
    //   startLoading();
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // if (
    //   response.data.message == "成功" &&
    //   router.app.$route.path == "/main/Home"
    // ) {
    //   requestCount--;
    //   if (loading && requestCount == 0) {
    //     endLoading();
    //   }
    // }
    if (response.data.message == "token失效或者不存在") {
      localStorage.removeItem("UserToken");
      localStorage.removeItem("userInfoEntity");
      localStorage.removeItem("identity");
      localStorage.removeItem("Isa_member");
      Message({
        duration: 1000,
        message: "请输入重新登录",
        type: "error",
      });
      // if (router.app.$route.path != "#/login") {
      //   window.location.href = "#/login";
      // }
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
function get(url, data) {
  return axios({
    url: http + url,
    method: "GET",
    params: data,
  });
}
function post(url, data) {
  return axios({
    url: http + url,
    method: "POST",
    data: data,
  });
}

function pay(data) {
  axios({
    url: http + "user/pay/creation",
    method: "POST",
    data: data,
  }).then((res) => {
    const div = document.createElement("div");
    let str = res.data.data;
    div.innerHTML = str;
    document.body.appendChild(div);
    document.forms[0].submit();
  });
}
export default {
  get,
  post,
  pay,
};
