// 过滤出对象中不相等的键值对
export function ObjectChangedValue(obj1, obj2) {
  let keys = Object.keys(obj1);
  let result = {};
  keys.forEach((k) => {
    if (obj1[k] != obj2[k]) {
      result[k] = obj2[k];
    }
  });
  return result;
}


export function GetUser() {
  return new Promise((resolve, reject) => {
    this.$get("user/coach/findById", {
      id: localStorage.getItem("coach_id"),
    }).then((res) => {
      if (res.data.code == 0) {
        resolve(res.data.data);
      } else
        this.$message({
          showClose: true,
          message: res.data.message,
          type: "error",
        });
    });
  });
}

export function Ts(message, ts) {
  if (ts) {
    this.$message({
      message: message,
      type: "success",
    });
  } else {
    this.$message.error(message);
  }
  return;
}
function rad(d) {
  return (d * Math.PI) / 180.0;
}

export function Distance(lat1, lng1, lat2, lng2) {
  var radLat1 = rad(lat1);
  var radLat2 = rad(lat2);
  var a = radLat1 - radLat2;
  var b = rad(lng1) - rad(lng2);
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000; //输出为公里
  var distance = s;
  var distance_str = "";
  if (parseInt(distance) >= 1) {
    distance_str = distance.toFixed(1) + "km";
  } else {
    distance_str = distance * 1000 + "m";
  }
  return s.toFixed(1);
}
// 防抖
export const DebounceBy = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }

    let callNow = !timer;

    timer = setTimeout(() => {
      timer = null;
    }, delay);

    if (callNow) fn.apply(this, args);
  };
};
// 时间
export function caculateTimeago(dateTimeStamp) {
  const minute = 1000 * 60; // 把分，时，天，周，半个月，一个月用毫秒表示
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const now = new Date().getTime(); // 获取当前时间毫秒

  const diffValue = now - dateTimeStamp; // 时间差

  let result = "";

  if (diffValue < 0) {
    return;
  }

  const minC = diffValue / minute; // 计算时间差的分，时，天，周，月

  const hourC = diffValue / hour;
  const dayC = diffValue / day;
  const weekC = diffValue / week;

  if (weekC >= 1 && weekC <= 4) {
    result = ` ${parseInt(weekC, 10)}周前`;
  } else if (dayC >= 1 && dayC <= 6) {
    result = ` ${parseInt(dayC, 10)}天前`;
  } else if (hourC >= 1 && hourC <= 23) {
    result = ` ${parseInt(hourC, 10)}小时前`;
  } else if (minC >= 1 && minC <= 59) {
    result = ` ${parseInt(minC, 10)}分钟前`;
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚";
  } else {
    const datetime = new Date();
    datetime.setTime(dateTimeStamp);
    const Nyear = datetime.getFullYear();
    const Nmonth =
      datetime.getMonth() + 1 < 10
        ? `0${datetime.getMonth() + 1}`
        : datetime.getMonth() + 1;
    const Ndate =
      datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
    result = `${Nyear}-${Nmonth}-${Ndate}`;
  }

  return result;
};
