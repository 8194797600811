<template>
  <div class="nr-boxz">
    <div class="nr" v-for="(item, index) in curriculum" :key="index" @click="tzcurriculum(item.id)">
      <div :class="['s-box']" :style="'background-image:url(' + item.face_url + ')'">
        <div class="s-nr" v-if="item.is_recommend">
          <!-- {{ item.categories_child }}
           -->
           优选
        </div>
      </div>

     
      <ul class="x-box">
        <li class="flex"
          style="color: #333333;font-size: 14px;margin-left: -8px;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          <samp style="margin-right: 10px;">
            【{{ item.course_num }}课时】{{ item.title }}
          </samp>
        </li>
        <li style="color: #B8B8B8;font-size: 12px;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;margin: 5px 0;">
          {{ item.categories_child }} <samp style="margin: 0 5px;">|</samp>{{ item.apply_age }}<samp
            style="margin: 0 5px;">|</samp>{{ item.course_num }}课时 <samp style="margin: 0 5px;">|</samp>
          {{ item.mechanism_name }}
        </li>
        <li class="f-zyz" v-if="item.card_type != '优学卡'">
          <p style="color: #FC781D;">
          <span style="font-size:12px ;">¥</span><span style="font-size: 20px;">{{ (item.amout-item.consume).toFixed(0) }}</span>
          </p>
          <p class="xf-box f-ai" style="margin: 5px 0;" v-if="item.consume>0">
            <img style="width: 14px;height: 14px;margin-right: 3px;" src="../../assets/xfj.png" alt="">
             <span>消费金可抵{{item.consume}}元</span>
          </p>
        </li>
        <li class="f-zyz" v-if="item.card_type == '优学卡'">
          <p style="color: #FC781D;">
          <span style="font-size:12px ;">¥</span><span style="font-size: 20px;">{{ item.discount_amout }}</span>
          </p>
          <p class="xf-box f-ai" style="margin: 5px 0;" v-if="item.consume>0">
            <img style="width: 14px;height: 14px;margin-right: 3px;" src="../../assets/xfj.png" alt="">
             <span>消费金可抵{{YouxueCard?item.consume:$store.state.NoVipPrice}}元</span>
          </p>
        </li>
        <li>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li>
        <!-- <li class="f-zy f-ai" v-if="YouxueCard && item.card_type == '优学卡'">
          <p style="font-size: 16px;color: #FC781D;margin-top: 10px;;">
            会员免费
          </p>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li> -->
        <!-- <li class="f-zy f-ai" v-if="YouxueCard && item.card_type != '优学卡'">
          <p style="font-size: 16px;color: #FC781D;margin-top: 10px;">
            {{ item.premium_point }}权益点
          </p>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li> -->
        <!-- <li style="margin-top: 10px;" class="f-zy f-ai" v-if="item.discount_amout != 99 && item.default_discount_price != 99 && !YouxueCard
        ">
          <div class="f-ai">
            <p style="font-size: 16px;color: #FC781D;margin-right: 10px;">
              ￥{{ item.YesPrice || 0 }}
            </p>
            <p class="f-ai"
              style="border-radius: 2px; font-size: 12px;color: #ffffff;padding: 0 4px; background: linear-gradient(360deg, #FC781D 0%, #FCC76B 100%);">
              最低券后价
            </p>
          </div>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li>
        <li class="flex" v-if="
          item.discount_amout == 99 &&
          item.default_discount_price == 99 &&
          !YouxueCard
        ">
          <p style="font-size: 16px;color: #FC8E6B;margin-right: 10px;">
            ￥{{ item.discount_amout }}
          </p>
        </li>
        <li style="color: #FC8E6B;font-size: 14px;margin-top: 7px;">
          门店平行底价：￥{{ item.amout }}
        </li> -->
      </ul>
      <!-- <div class="f-jsb" style=" padding: 10px 15px;">
        <div class="f-ai">
     
          <samp
            style="width: 150px; font-size: 14px;color: #666666;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">{{
    item.mechanism_name
            }}</samp>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["curriculum"],
  data() {
    return {
      YouxueCard: false
    };
  },
  mounted() {
    console.log(this.curriculum, 213);
    if (this.$store.state.YouxueCard.length > 0) {
      this.YouxueCard = true;
    }
    if (JSON.parse(localStorage.getItem("Isa_member"))) {
      if (JSON.parse(localStorage.getItem("Isa_member")).length != 0) {
        this.YouxueCard = true
      }
      return
    }
    this.getInfo()
    // 获取是否是会员

  },
  methods: {
    getInfo() {
      if (JSON.parse(localStorage.getItem("userInfoEntity"))) {
        let url = "user/userMember/queryListPage";
        let data = {
          is_teach_paypal: true,
          user_id: JSON.parse(localStorage.getItem("userInfoEntity")).user_id,
          is_member: true,
          pageSize: 10,
          currentPage: 1,
          type: "优学卡",
          is_teach_paypal: true
        };
        this.$get(url, data).then(res => {
          
          if (res.data.data.length > 0) {
            this.YouxueCard = true;
          }
        });
      }
    },

    tzcurriculum(id) {
      // 课程详细
      this.$router.push({
        name: "curriculum",
        query: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped lang="less">

.xf-box{
  padding: 4px;
  color: #FFC364;
  font-size: 12px;
  border: 1px solid #FFC364;
  border-radius: 5px;
}
.nr-boxz {
  cursor: pointer;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(5, 224px);

  .nr:hover {
    background-color: #00000010;
  }

  .nr {
    // border: 1px solid #dddddd;
    border-radius: 10px;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);

    .s-box {
      display: flex;
      justify-content: space-between;
      height: 151px;
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      background-size:cover;



      border-radius: 10px 10px 0 0;

      .s-nr {
        background-color: #e64949;
        color: #ffffff;
        height: 29px;
        width: 53px;
        line-height: 29px;
        text-align: center;
        border-radius: 10px 0 10px 0;

      }

      .x-li-div {
        background-image: url("../../assets/zbjt.png");
        background-size: cover;
        width: 103px;
        height: 23px;
        font-size: 14px;
        color: #fc781d;
        border-radius: 0px 10px 0px 10px;

      }
    }

    .s-box2 {
      height: 181px;
    }

    .x-box {
      padding: 17px 12px 20px 12px;
      // border-bottom: 1px solid #dddddd;
    }
  }
}
</style>
