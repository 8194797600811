<template>
  <div class="nr-boxz">
    <div class="nr">
      <div class="s-box">
        <div class="s-nr">
          优选
        </div>
        <div class="x-li-div f-ai">
          <samp style="margin-right: 15px;margin-left: 10px;">券</samp>
          <samp>可用2张</samp>
        </div>
      </div>
      <div
        style="background-color: #FC781D;height: 28px;text-align: center;line-height: 28px;color: #ffffff;font-size: 14px;"
      >
        可补贴600元
      </div>
      <ul class="x-box">
        <li
          class="flex"
          style="color: #333333;font-size: 16px;margin-left: -8px;"
        >
          <samp>
            【20课时】游泳一对多（4-6人）
          </samp>
        </li>
        <li style="color: #B8B8B8;font-size: 14px;">
          游泳 <samp style="margin: 0 10px;">|</samp> 6岁以上<samp
            style="margin: 0 10px;"
            >|</samp
          >20课时
        </li>
        <li class="f-ai" style="margin: 15px 0;">
          <p style="font-size: 16px;color: #FC781D">
            ￥1600
          </p>
          <p
            class="f-ai"
            style=";margin:0 10px;border-radius: 2px; font-size: 12px;color: #ffffff;padding: 2px 4px; background: linear-gradient(360deg, #FC781D 0%, #FCC76B 100%);"
          >
            最低券后价
          </p>
          <s style="color: #B8B8B8;font-size: 14px;">￥1200</s>
        </li>
        <li style="color: #B8B8B8;font-size: 14px;">已销售90</li>
      </ul>
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.nr-boxz {
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: repeat(3, 360px);
  .nr {
    border: 1px solid #dddddd;
    border-radius: 10px;
    .s-box {
      display: flex;
      justify-content: space-between;
      height: 151px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .x-li-div {
        background-image: url("../../assets/zbjt.png");
        background-size: cover;
        width: 103px;
        height: 23px;
        font-size: 14px;
        color: #fc781d;
        border-radius: 0px 10px 0px 0px;
      }
      .s-nr {
        background-color: #e64949;
        color: #ffffff;
        height: 29px;
        width: 53px;
        line-height: 29px;
        text-align: center;
        border-radius: 10px 0px 10px 0px;
      }
    }
    .x-box {
      padding: 17px 15px 20px 15px;
    }
  }
}
</style>
