<template>
  <div>
    <div class="nr-box" v-for="(item, index) in list" :key="index">
      <div class="nr-s flex">
        <div class="left f-z">{{ item.cash }}元</div>
        <ul class="right-ul f-zy">
          <li>
            <p style="color: #333333;font-size: 16px;padding-top: 10px;">
              {{ item.coup_name }}
            </p>
            <p style="margin: 5px 0;color: #666666;font-size: 14px;">
              适用于购买课程直接抵扣
            </p>
            <p style="color: #666666;font-size: 14px;">
              {{ item.create_time }} - {{ item.overdue_time }}
            </p>
          </li>
          <li v-if="item.status == 1" class="right-li">
            <p style="margin-bottom: 10px;color: #FFFFFF;background-color: #FC781D;cursor: pointer;"
              @click="tzBasketball">
              立即使用
            </p>
            <p v-if="item.type == '消费券'" style="border: 1px solid #FC781D;color: #FC781D;cursor: pointer;"
              @click="open1">
              赠送
            </p>
          </li>
          <li v-if="item.status == 2" class="right-li">
            <img src="../assets/ysx.png" style="width: 77px;height: 61px;" alt="" />
          </li>
          <li v-if="item.status == 3" class="right-li">
            <p style="color: #FFFFFF;background-color: #707070;">已使用</p>
          </li>
        </ul>
      </div>
      <div class="nr-x">
        全城机构所有课程(青少年活动中心除外)都可以使用，可无限购买、叠加使用，未用可退。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
  methods: {
    open1() {
      this.$message('请前往小程序或者app');
    },
    tzBasketball() {
      this.$router.push({
        name: "Basketball"
      });
    }
  }
};
</script>

<style scoped lang="less">
.nr-box {
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #dddddd;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .nr-s {
    .left {
      color: #ffffff;
      height: 90px;
      width: 90px;
      background-color: #fc781d;
      border-radius: 10px 0px 0px 0px;
    }

    .right-ul {
      flex: 1;
      padding: 0 20px 0 10px;

      .right-li {
        display: flex;
        flex-direction: column;
        justify-content: center;

        >p {
          width: 104px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          border-radius: 20px;
        }
      }
    }
  }

  .nr-x {
    color: #666666;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    border-top: 1px solid #dddddd;
  }
}
</style>
