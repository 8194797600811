<template>
    <div class="BlanSpace f-zz">
        <div style="text-align: center;">
            <img src=".././assets/wnr.png" style="height: 400px;width: 350px;" alt="">
            <div style="font-size: 14px;color: #999;">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: '空空的~'
        }
    },
    data() {
        return {
        }
    }, methods: {

    }
}
</script>

<style lang="less" scoped>
.BlanSpace {
    height: 500px;
}
</style>