<template>
    <div class="box">
        <div class="nr-box" v-for="(item, index) in Venue" :key="index" @click="tzmechanism(item.id)">
            <img :src="item.images" style="width: 100%;height: 150px;" alt="">
            <div class="nr">
                <!-- text-overflow: ellipsis;overflow: hidden;width: 100%; white-space:nowrap; -->
                <p style="font-size: 16px;"> {{ item.name }}</p>
                <p style="margin: 4px 0;color: #666;">{{ item.categories_child }}</p>
                <p style="color: #999;">{{ item.address }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["Venue"],
    data() {
        return {};
    },
    methods: {
        tzmechanism(id) {
            this.$router.push({
                name: "Venuedetailed",
                query: {
                    id: id,
                }
            });

        }
    }
};
</script>

<style scoped lang="less">
.box {
    cursor: pointer;
    display: grid;
    grid-gap: 20px 20px;
    grid-template-columns: repeat(5, 224px);

    .nr-box:hover {
        background-color: #00000010;
    }

    .nr-box {
        border-radius: 10px;
        background-color: #ffffff;
        overflow: hidden;
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);


        .nr {
            padding: 10px;
            height: 141px;
            font-size: 14px;
        }
    }
}
</style>
