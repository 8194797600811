<template>
  <el-upload class="avatar-uploader" :action="domain" :data="QiNiYunL" list-type="picture-card"
    :on-success="UploadFaceImg" :before-upload="gettoken" :file-list="fileList" :on-remove="handleRemove"
    ref="imgsUploader" :auto-uploa="false" :limit="3">
    <i class="el-icon-plus avatar-uploader-icon"></i>
    <div slot="fileList" slot-scope="{ file }">
      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
      <span class="el-upload-list__item-actions">
        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove()">
        </span>
      </span>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: {
    url: {
      type: Array
    }
  },

  data() {
    return {
      textarea: "",
      imgList: [],
      fileList: [],
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: ""
      },
      disabled: false
    };
  },
  watch: {
    url: {
      handler(newVal, oldVal) {
        if (newVal) {
          newVal.forEach(element => {
            this.fileList.push({
              url: element
            })
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.gettoken();
  },
  methods: {
    handleRemove(file) {
      let imgIndex;
      if (file.response) {
        imgIndex = this.imgList.findIndex((item, index, arr) => {
          return item == this.upload_qiniu_addr + file.response.key;
        });
      } else {
        imgIndex = this.imgList.findIndex((item, index, arr) => {
          return item == file.url;
        });
      }
      this.imgList.splice(imgIndex, 1);
      this.$emit("getImgUrl", this.imgList.join(","));
    },
    UploadFaceImg(file) {
      this.imgList.push(this.upload_qiniu_addr + file.key);
      this.$emit("getImgUrl", this.imgList.join(","));
    },
    gettoken() {
      var timestamp = new Date().getTime();
      let url = "https://www.curiousmore.com/qmore/push/upload/getToken";
      let data = {
        key: timestamp + ".png",
        type: "pic"
      };
      this.axios({
        url: url,
        method: "POST",
        data: data
      })
        .then(res => {
          this.QiNiYunL.token = res.data.data;
          this.QiNiYunL.key = data.key;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
.avatar-uploader {
  height: 100px !important;
}

.avatar-uploader .el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}

.avatar-uploader .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.avatar-uploader .el-upload-list--picture-card .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}
</style>
