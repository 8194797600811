// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import "less";
import "./assets/icon.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import AMapLoader from "@amap/amap-jsapi-loader";
let map = AMapLoader.load({
  key: "c0c6e86a15067065d61ffb5d309f8308", // 申请好的Web端开发者Key，首次调用 load 时必填
  version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  AMapUI: {
    // 是否加载 AMapUI，缺省不加载
    version: "1.1", // AMapUI 缺省 1.1
    plugins: ["misc/PoiPicker"], // 需要加载的 AMapUI ui插件
  },
  Loca: {
    // 是否加载 Loca， 缺省不加载
    version: "1.3.2", // Loca 版本，缺省 1.3.2
  },
});
Vue.prototype.$map = map;

import axios from "axios";
Vue.prototype.axios = axios;
import api from "./api";
Vue.prototype.$get = api.get;
Vue.prototype.$post = api.post;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import componentLsit from "./componentLsit/index";
Vue.use(componentLsit);

import { ObjectChangedValue, Ts, Distance } from "./api/public.js";
Vue.prototype.$ObjectChangedValue = ObjectChangedValue;
Vue.prototype.$Ts = Ts;
Vue.prototype.$Distance = Distance;

Vue.prototype.timeToTemp = function (time) {
  return new Date(time.replace(/\-/g, "/")).getTime();
};

Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

import TIM from "tim-js-sdk";
let options = {
  SDKAppID: 1400782437, // 接入时需要将0替换为您的即时通信应用的 SDKAppID
};

import TIMUploadPlugin from "tim-upload-plugin";

let im = TIM.create(options);
im.registerPlugin({
  "tim-upload-plugin": TIMUploadPlugin,
});
Vue.prototype.$tim = im;
Vue.prototype.$tims = TIM;
im.setLogLevel(1);
Vue.config.productionTip = false;
import store from "./store";

/* eslint-disable no-new */
new Vue({
  el: "#app",
  components: {
    App,
  },
  template: "<App/>",
  router,
  store,
});
