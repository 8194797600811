<template>
  <div class="nr-boxz">
    <div class="nr" v-for="(item, index) in mechanism" :key="index" @click="tzmechanism(item.id)">
      <div v-if="item.mechanism_logo" class="s-box" :style="' background-image:url(' + item.mechanism_logo + ')'">
        <div v-if="item.is_recommend" class="s-nr" style="text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          <!-- {{ item.categories_child }} -->
          优选
        </div>
      </div>
      <div v-else class="s-box" :style="' background-image:url(' + item.face_url + ')'">
        <div class="s-nr" style="text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          {{ item.categories_child }}
        </div>
      </div>
      <ul class="x-box">
        <li style="color:#333333;font-size: 16px; text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          {{ item.mechanism_name }}
        </li>
        <li style="margin:0 0 10px 0;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;" class="f-ai">
          <samp v-for="(item2, index2) in item.avg_rating" :key="index2" style="font-size: 12px;color: #FC781D;">★</samp>
          <samp style="color: #E64949;font-size: 14px;margin-left: 10px;">{{ item.avg_rating }}.0分</samp>
        </li>
        <!-- <li
          class="f-ai f-zy"
          style="margin-top: 10px;"
          v-for="(item3, index3) in item.map.masterSetPriceEntity"
          :key="index3"
        >
          <div class="f-ai">
            <p
              style="font-size:14px; border-radius: 2px;padding:0 3px;color: #FC781D;border: 1px solid #FC781D;"
            >
              热门推荐
            </p>
            <p
              v-if="item3.course_num"
              style="width: 70%;font-size: 16px;color: #666666;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;"
            >
              【{{ item3.course_num }}课时】{{ item3.title }}
            </p>
            <p v-else style="font-size: 16px;color: #666666;margin-left: 8px;">
              {{ item3.title }}
            </p>
          </div>
          <samp
            v-if="
              item3.amout &&
                item3.default_discount_price != 99 &&
                item3.discount_amout != 99
            "
            style="color: #FC781D;font-size: 16px;"
            >￥{{ item3.amout }}</samp
          >
          <samp
            v-if="
              item3.default_discount_price == 99 && item3.discount_amout == 99
            "
            style="color: #FC781D;font-size: 16px;"
            >￥{{ item3.default_discount_price }}</samp
          >
        </li> -->
      </ul>
      <div class="f-zy f-ai" style="padding:0 15px 28px 15px;">
        <p style="width: 100%;font-size: 14px;color: #666666;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          {{ item.mechanism_addr }}
        </p>
        <!-- <samp style="color: #B8B8B8;font-size: 14px;">已售</samp> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mechanism", 'cg'],
  data() {
    return {};
  },
  methods: {
    tzmechanism(id) {
      if (this.cg == 1) {
        this.$router.push({
          name: "mechanism",
          query: {
            id: id,
            cg: 1,
          }
        });
      } else {
        this.$router.push({
          name: "mechanism",
          query: {
            id: id,
          }
        });
      }

    }
  }
};
</script>

<style scoped lang="less">
.nr-boxz {
  cursor: pointer;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(5, 224px);

  .nr:hover {
    background-color: #00000010;
  }

  .nr {
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);

    // border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: #ffffff;


    .s-box {
      display: flex;
      justify-content: flex-end;
      height: 150px;
      background-repeat: no-repeat;
      background-size:cover;
      border-radius: 10px 10px 0 0;

      .s-nr {
        background-color: #e64949;
        color: #ffffff;
        height: 29px;
        padding: 0 5px;
        line-height: 29px;
        text-align: center;
        border-radius: 0px 10px 0px 10px;
      }
    }

    .x-box {
      padding: 17px 15px 0px 15px;
      // border-bottom: 1px solid #dddddd;
    }
  }
}
</style>
