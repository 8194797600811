<template>
  <div class="nr-boxz">
    <div class="nr" v-for="(item, index) in Morecourses" :key="index" @click="tzcurriculum(item.id)">
      <div :class="[
        's-box',
        !YouxueCard && item.card_type == '优学卡' ? 's-box2' : ''
      ]" :style="'background-image:url(' + item.face_url + ')'">
        <div class="s-nr">
          {{ item.categories_child }}
        </div>
        <div class="x-li-div f-ai" v-if="!YouxueCard && item.card_type != '优学卡'">
          <samp style="margin-right: 15px;margin-left: 10px;">券</samp>
          <samp>可用{{ item.Yes }}张</samp>
        </div>
      </div>
      <div v-if="YouxueCard"
        style="background-color: #FC781D;height: 28px;text-align: center;line-height: 28px;color: #ffffff;font-size: 14px;">
        可补贴{{ item.card_type == "优学卡" ? item.amout : item.member }}元
      </div>
      <div v-if="!YouxueCard && item.card_type != '优学卡'"
        style="background-color: #FC781D;height: 28px;text-align: center;line-height: 28px;color: #ffffff;font-size: 14px;">
        最高可补贴{{ item.Yes * 100 }}元
      </div>

      <ul class="x-box">
        <li class="flex" style="color: #333333;font-size: 16px;margin-left: -8px;">
          <samp style="margin-right: 10px;">
            【{{ item.course_num }}课时】{{ item.title }}
          </samp>
        </li>

        <li style="color: #B8B8B8;font-size: 12px;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">
          {{ item.categories_child }} <samp style="margin: 0 5px;">|</samp>{{ item.apply_age }}<samp
            style="margin: 0 5px;">|</samp>{{ item.course_num }}课时 <samp style="margin: 0 5px;">|</samp>
          {{ item.mechanism_name }}
        </li>
        <li class="f-zy f-ai" v-if="YouxueCard && item.card_type == '优学卡'">
          <p style="font-size: 16px;color: #FC781D;margin-top: 10px;;">
            会员免费
          </p>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li>
        <li class="f-zy f-ai" v-if="YouxueCard && item.card_type != '优学卡'">
          <p style="font-size: 16px;color: #FC781D;margin-top: 10px;">
            {{ item.premium_point }}权益点
          </p>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li>
        <li style="margin-top: 10px;" class="f-zy f-ai" v-if="
          item.discount_amout != 99 &&
          item.default_discount_price != 99 &&
          !YouxueCard
        ">
          <div class="f-ai">
            <p style="font-size: 16px;color: #FC781D;margin-right: 10px;">
              ￥{{ item.YesPrice }}
            </p>
            <p class="f-ai"
              style="border-radius: 2px; font-size: 12px;color: #ffffff;padding: 0 4px; background: linear-gradient(360deg, #FC781D 0%, #FCC76B 100%);">
              最低券后价
            </p>
          </div>
          <samp style="color: #B8B8B8;font-size: 14px;">已售{{ item.pay_num }}</samp>
        </li>
        <li class="flex" v-if="
          item.discount_amout == 99 &&
          item.default_discount_price == 99 &&
          !YouxueCard
          
        ">
          <p style="font-size: 16px;color: #FC8E6B;margin-right: 10px;">
            ￥{{ item.discount_amout }}
          </p>
        </li>
        <li style="color: #FC8E6B;font-size: 14px;margin-top: 7px;">
          门店平行底价：￥{{ item.amout }}
        </li>
      </ul>
      <!-- <div class="f-jsb" style=" padding: 10px 15px;">
        <div class="f-ai">
     
          <samp
            style="width: 150px; font-size: 14px;color: #666666;text-overflow:ellipsis; white-space:nowrap;overflow:hidden;">{{
    item.mechanism_name
            }}</samp>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["Morecourses"],
  data() {
    return {
      YouxueCard: false
    };
  },
  mounted() {
    if (this.$store.state.YouxueCard.length > 0) {
      this.YouxueCard = true;
    }
  },
  methods: {
    tzcurriculum(id) {
      // 课程详细
      this.$router.push({
        name: "curriculum",
        query: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.nr-boxz {
  cursor: pointer;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(4, 285px);

  .nr:hover {
    background-color: #00000010;
  }

  .nr {
    // border: 1px solid #dddddd;
    border-radius: 10px;

    .s-box {
      display: flex;
      justify-content: space-between;
      height: 151px;
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      background-size: 100%;

      border-radius: 10px 10px 0 0;

      .s-nr {
        background-color: #e64949;
        color: #ffffff;
        height: 29px;
        width: 53px;
        line-height: 29px;
        text-align: center;
        border-radius: 10px 0 10px 0;

      }

      .x-li-div {
        background-image: url("../../assets/zbjt.png");
        background-size: cover;
        width: 103px;
        height: 23px;
        font-size: 14px;
        color: #fc781d;
        border-radius: 0px 10px 0px 10px;

      }
    }

    .s-box2 {
      height: 181px;
    }

    .x-box {
      padding: 17px 15px 20px 15px;
      // border-bottom: 1px solid #dddddd;
    }
  }
}
</style>
