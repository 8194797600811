import Vue from "vue";
import Vuex from "vuex";
import api from "../api";
import { Ts } from "../api/public.js";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    YouxueCard: "",
    Search: "",
    position: {
      latitude: 30.25727,
      longitude: 120.20523,
      city: "杭州市",
    },
    NoVipPrice: 70,
    need: {},
    TimUser_id: "",
    conversationID: "",
  },
  mutations: {
    SET_C(state, parent) {
      state.YouxueCard = parent;
    },
    SET_Search(state, parent) {
      state.Search = parent;
    },
    SET_Need(state, parent) {
      state.need = parent;
    },
    SET_TimUser_id(state, parent) {
      state.TimUser_id = parent;
    },
    SET_conversationID(state, parent) {
      state.conversationID = parent;
    },
  },
  actions: {
    GetNeed(state, id) {
      api
        .get("user/userCourseNeed/findById", {
          id: id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            let data = res.data.data;
            let payload = {
              description: "用户课程需求",
              data: "need",
              extension: JSON.stringify({
                study_base: data.study_base,
                categories: data.title,
                title: data.title,
                id: data.id,
                address: data.address,
                service_type: data.service_type,
              }),
            };
            state.commit("SET_Need", payload);
          } else {
            Ts("自动发送失败");
          }
        });
    },
  },
});
export default store;
