<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  mounted() {
    if (window.screen.width < 1024) {
      document.getElementsByClassName('app')[0].style.width = 1400 + 'px'
      var t = window.screen.width / 1400;
      document.getElementsByClassName('app')[0].style.zoom = t
    }
  },


}
</script>

<style lang="less">
.shou {
  cursor: pointer;
}

.app {
  font-family: "Microsoft YaHei";
  font-weight: normal;
  width: 100%;
  font-weight: 400;

  // overflow-x: hidden;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}


.el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}

.el-message-box {
  border-radius: 15px;
}

.el-tabs {
  background-color: #ffffff;

  .el-tabs__item {
    font-size: 16px !important;

    &:hover {
      color: #ff7e00 !important;
    }
  }

  .is-active {
    color: #ff7e00 !important;
  }

  .el-tabs__active-bar {
    background-color: #ff7e00 !important;
    border: none;
  }

  .el-tabs__header {
    margin: 0;
  }
}

.an-app {
  border-radius: 4px;
  padding: 8px 24px;
  background-color: #FC781D;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: inline-block
}

.an-Press {
  border: 1px solid #FC781D;
  padding: 4px 12px;
  // height: 32px;
  color: #FC781D;
  border-radius: 100px;
  // text-align: center;
  font-size: 14px;
  cursor: pointer;
  // line-height: 32px;
  display: inline-block
}

.Press {
  border-radius: 100px;
  // min-width: 76px;
  // height: 29px;
  // line-height: 29px;
  padding: 4px 12px;
  background-color: #FC781D;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: inline-block
}

.cell {
  text-align: center;
}

.z12-33 {
  font-size: 12px;
  color: #333333;
}

.z12-ff {
  font-size: 12px;
  color: #ffffff;
}


.z14-33 {
  font-size: 14px;
  color: #333333;
}

.z16-33 {
  font-size: 16px;
  color: #333333;
}

.z18-33 {
  font-size: 18px;
  color: #333333;
}

.z20-33 {
  font-size: 20px;
  color: #333333;
}

.z14-ff {
  font-size: 14px;
  color: #ffffff;
}

.z16-ff {
  font-size: 16px;
  color: #ffffff;
}

.z18-ff {
  font-size: 18px;
  color: #ffffff;
}

.z20-ff {
  font-size: 20px;
  color: #ffffff;
}

.z12-bb {
  font-size: 12px;
  color: #B8B8B8;
}

.z14-bb {
  font-size: 14px;
  color: #B8B8B8;
}

.z16-bb {
  font-size: 16px;
  color: #B8B8B8;
}

.z18-bb {
  font-size: 18px;
  color: #B8B8B8;
}

.z20-bb {
  font-size: 20px;
  color: #B8B8B8;
}
.z12-66 {
  font-size: 12px;
  color: #666666;
}
.z14-66 {
  font-size: 14px;
  color: #666666;
}

.z16-66 {
  font-size: 16px;
  color: #666666;
}

.z18-66 {
  font-size: 18px;
  color: #666666;
}

.z20-66 {
  font-size: 20px;
  color: #666666;
}

.center {
  margin: 0 auto;
  width: 1240px;
}

.heart {
  margin: 0 auto;
  width: 1240px;
  border-radius: 4px;
  background-color: #ffffff;
}

.flex {
  display: flex;
}

.f-ai {
  display: flex;
  align-items: center;
}

.f-z {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-zz {
  display: flex;
  justify-content: center;
}

.f-js {
  display: flex;
  justify-content: flex-end;

}

.f-ks {
  display: flex;
  justify-content: flex-start;

}


.f-zy {
  display: flex;
  justify-content: space-between;
}

.f-zyz {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.f-sx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.f-sxq {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.f-l {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f-jsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
