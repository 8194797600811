<template>
    <div class="coach">
        <div class="nr-box" v-for="(item, index) in coach" :key="index" @click="jump(item.id)">
            <div class="nr-top" :style="'background-image:url(' + item.head_portrait + ')'">
                <div class="top-bt">
                    {{ change(item.people_service) }}
                </div>
                <!-- <img class="img" :src="item.head_portrait" alt=""> -->
            </div>
            <div class="nr">
                <div>
                    <span class="z14-33">{{ item.real_name.substr(0, 1) + '教练' }}</span>
                    <p class="nr-p">{{ item.teaching_categories }}</p>
                </div>
                <div style="font-size: 12px;color: #B8B8B8;margin: 10px 0;">
                    <span style="color: #FC781D;margin-right: 8px;">★★★★★</span>
                    <span>{{ item.sex == 1 ? '男' : '女' }}</span>
                    <span style="margin: 0 5px;">|</span>
                    <span>{{ class_certificate[item.class_certificate] }}</span>
                    <span style="margin: 0 5px;">|</span>
                    <span>教龄{{ item.teaching_age }}年</span>
                </div>
                <div class="f-ai">
                    <div class="way" v-if="item.teaching_type.indexOf('2')">上门教学</div>
                    <div class="way way2" v-if="item.teaching_type.indexOf('3')">自带场馆</div>
                </div>
                <div style="color: #B8B8B8;font-size: 12px;margin-top: 10px;" v-if="latitude && item.latitude">
                    {{ $Distance(latitude, longitude, item.latitude, item.longitude) + 'kn' }}
                </div>
                <div v-else class="z14-66" style="margin-top: 10px;">
                    未知
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        coach: {
            type: Array,
        }
    },
    data() {
        return {
            class_certificate: {
                '1': '初级',
                '2': '中级',
                '3': '高级',
                '4': '特级'
            },
            people_service: {
                '1': '成人',
                '2': '学龄前',
                '3': '学龄',
                '4': '亲子'
            },
            latitude: '',
            longitude: '',
        }
    },
    mounted() {
        if (localStorage.getItem('MyIocation')) {
            this.latitude = JSON.parse(localStorage.getItem('MyIocation')).latitude
            this.longitude = JSON.parse(localStorage.getItem('MyIocation')).longitude
        }
    },
    methods: {
        jump(e) {
            this.$router.push({
                name: 'coachDetailed',
                query: {
                    id: e
                }
            })
        },
        change(e) {
            let data = []
            let e1 = e.split(',')
            for (let i = 0; i < e1.length; i++) {
                const element = e1[i];
                data.push(this.people_service[element])
            }
            return data.join(',')
        }
    }
}
</script>

<style lang="less" scoped>
.coach {
    display: grid;
    grid-template-columns: repeat(5, 224px);
    grid-gap: 20px 30px;

    .nr-box {
        background-color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
         
        &:hover {
            background-color: #33333305;
        }
    }


    .nr {
        padding: 20px 14px;

        .way {
            padding: 2px 8px;
            font-size: 12px;
            border-radius: 2px;
            color: #72A76A;
            display: inline-block;
            border: 1px solid #72A76A;
            margin-right: 5px;
        }

        .way2 {
            color: #5798F4;
            border: 1px solid #5798F4;
        }

        .nr-p {
            border: 1px solid #FC781D;
            border-radius: 10px;
            color: #FC781D;
            padding: 2px 8px;
            display: inline-block;
            font-size: 12px;
            margin-left: 8px;
        }
    }

    .nr-top {
        height: 140px;
        border-radius: 10px 10px 0 0;
        background-size: 100% 100%;
        // background-repeat: repeat-x;
        // background-position: ;
        position: relative;

        .top-bt {
            padding: 5px 12px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #E64949;
            border-radius: 10px 0 10px 0;
            color: #ffffff;
        }

        .img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
            // vertical-align: baseline;
        }
    }

}
</style>